import { css } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

export const interactiveMapCss = () => css`
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 270px 1fr;

  .open-menu-btn {
    display: none;
  }

  ${mobile} {
    display: flex;

    .open-menu-btn {
      z-index: 999;
      display: block;
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }

  .map-container {
    position: relative;
    width: 100%;
    overflow: hidden;

    .key-container {
      pointer-events: none;
      z-index: 9999;
      position: absolute;
    }

    ${mobile} {
      .key-container {
        top: 10px;
        left: 35%;
      }
    }

    .icon-tooltip {
      border: none;
      background: var(--shade9);
      border-radius: var(--br);
      color: var(--shade0);
      padding: var(--sp-3);
    }

    .custom-icon {
      position: relative;

      width: 36px;
      height: 36px;

      img {
        --w: 36px;
        width: var(--w);
        height: var(--w);

        position: absolute;
        inset: 0;
      }
    }

    .custom-text-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;

      .custom-label {
        text-align: center;
        color: var(--shade0);
        text-shadow:
          var(--sp-px) 0 0 var(--shade10),
          0 var(--sp-px) 0 var(--shade10),
          calc(-1 * var(--sp-px)) 0 0 var(--shade10),
          0 calc(-1 * var(--sp-px)) 0 var(--shade10),
          var(--sp-px) var(--sp-px) 0 var(--shade10),
          calc(-1 * var(--sp-px)) var(--sp-px) 0 var(--shade10),
          var(--sp-px) calc(-1 * var(--sp-px)) 0 var(--shade10),
          calc(-1 * var(--sp-px)) calc(-1 * var(--sp-px)) 0 var(--shade10);
      }
    }
  }

  .map-settings {
    width: 100%;
    height: 100%;

    position: relative;
    display: flex;
    flex-direction: column;

    background: var(--shade9);
    box-shadow: 0 0 25px rgba(8, 7, 19, 0.6);

    overflow: hidden;

    transition: width var(--transition-long);

    .close-menu-btn {
      display: none;
    }

    ${mobile} {
      z-index: 99999;
      position: absolute;
      top: 0;
      left: 0;

      width: var(--w);

      .close-menu-btn {
        display: block;
        position: absolute;
        top: var(--sp-3);
        right: var(--sp-3);
      }
    }

    .title {
      border-bottom: 1px solid var(--shade3-25);
      padding: var(--sp-5) var(--sp-2) var(--sp-2);
    }

    .category {
      padding: 0 var(--sp-2);
    }

    .desc {
      padding: 0 var(--sp-2);
      text-align: center;
    }

    fieldset {
      border: none;
      padding: 0;
      padding-top: var(--sp-2);

      label {
        cursor: pointer;
        border-radius: var(--br);
        padding: var(--sp-2) var(--sp-2);

        &:hover {
          background-color: var(--shade6);
          box-shadow: var(--highlight);
        }
      }
    }

    fieldset:not(:last-child) {
      border-bottom: 1px solid var(--shade3-25);
    }

    .search-container {
      padding-right: var(--sp-3);
      width: 270px;

      & > label {
        width: 100%;
      }
    }
  }

  .scroll-container {
    height: 100%;
    overflow: hidden auto;
    &::-webkit-scrollbar {
      width: var(--sp-3);
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: var(--sp-1) solid var(--shade7);
      border-radius: var(--br);
    }
  }
`;
