import React, { useId, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.js";
import { LoadingSpinner } from "clutch/src/LoadingSpinner/LoadingSpinner.js";
import { cssToggleSwitch } from "clutch/src/ToggleSwitch/ToggleSwitch.js";

import * as Styled from "@/game-fortnite/InteractiveMap.style.jsx";
import { useDynamicSettingsKeys } from "@/game-fortnite/utils/use-dynamic-settings-keys.mjs";
import { useWikiData } from "@/game-fortnite/utils/use-wiki-data.mjs";
import MenuIcon from "@/inline-assets/menu-icon.svg";
import LeafletSharedCss from "@/shared/leaflet-shared-css.mjs";
import { classNames } from "@/util/class-names.mjs";
import { lazyComponent } from "@/util/lazy-component.mjs";
import { useQueryToggle } from "@/util/router-hooks.mjs";
import useFullWidthPage from "@/util/use-full-width-page.mjs";

type SettingsKey = string;

const Map = lazyComponent(() => import("@/game-fortnite/MapContainer.jsx"));

const InteractiveMap = () => {
  useFullWidthPage();

  return (
    <div {...classNames(Styled.interactiveMapCss(), LeafletSharedCss())}>
      <MapSettings />

      <div className="map-container">
        <Map fallback={<LoadingSpinner />} />
      </div>
    </div>
  );
};

InteractiveMap.fullBleed = true;

function MapSettings() {
  const { t } = useTranslation();
  const settingsKeys = useDynamicSettingsKeys();
  const { mapData } = useWikiData();
  const [menuVisible, setVisible] = useState<boolean>(true);

  if (!mapData)
    return (
      <div
        className="map-settings type-body1-form--active shade0 loading-row"
        style={{ "--w": menuVisible ? "270px" : "0" }}
      />
    );

  return (
    <>
      <div className="open-menu-btn">
        <Button onClick={() => setVisible(true)}>
          <span className="visually-hidden">{t("common:hide", "Hide")}</span>
          {<MenuIcon />}
        </Button>
      </div>
      <div
        className="map-settings type-body1-form--active shade0 scroll-container"
        style={{ "--w": menuVisible ? "270px" : "0" }}
      >
        <div className="close-menu-btn">
          <Button onClick={() => setVisible(false)}>
            <span className="visually-hidden">{t("common:hide", "Hide")}</span>
            <MenuIcon />
          </Button>
        </div>
        <h1 className="type-article-headline title">
          {t("fortnite:map.title", "Fortnite Map")}
        </h1>
        <form className="flex column">
          <fieldset className="flex column gap-1">
            <h2 className="type-h6 shade1 category">
              {t("fortnite:map.section.spawns", "Spawns")}
            </h2>
            <p className="type-subtitle shade1 desc">
              {t(
                "fortnite:map.section.spawns.desc",
                "* gold outline = 100% spawn rate",
              )}
            </p>
            {settingsKeys
              .filter(
                (keyName) =>
                  keyName[0] !== "namedLocations" && keyName[0] !== "landmarks",
              )
              .map(([key, name, subName]) => (
                <DataToggle
                  key={key}
                  paramKey={key}
                  label={t(`fortnite:map.option.${key}`, name)}
                  subLabel={t(`fortnite:map.option.${key}`, subName)}
                />
              ))}
          </fieldset>
          <fieldset className="flex column gap-1">
            <h2 className="type-h6 shade1 category">
              {t("fortnite:map.section.locations", "Locations")}
            </h2>
            {settingsKeys
              .filter(
                (keyName) =>
                  keyName[0] === "namedLocations" || keyName[0] === "landmarks",
              )
              .map(([key, name]) => (
                <DataToggle
                  key={key}
                  paramKey={key}
                  defaultValue={key === "namedLocations" ? true : undefined}
                  label={t(`fortnite:map.option.${key}`, name)}
                />
              ))}
          </fieldset>
        </form>
      </div>
    </>
  );
}

function DataToggle({
  label,
  subLabel,
  paramKey,
  defaultValue = false,
}: {
  label: string;
  subLabel?: string;
  paramKey: SettingsKey;
  defaultValue?: boolean;
}) {
  const id = `map-${useId()}`;
  const [checked, toggle] = useQueryToggle(paramKey, defaultValue);

  return (
    <label className="flex align-center justify-between" htmlFor={id}>
      <div>
        <div>{label}</div>
        {subLabel ? (
          <div className="type-caption--semi">{subLabel}</div>
        ) : (
          <div></div>
        )}
      </div>
      <div className={cssToggleSwitch()} data-active={checked} aria-hidden>
        <div className="inner" />
      </div>
      <input
        id={id}
        className="visually-hidden"
        type="checkbox"
        defaultChecked={checked}
        onChange={toggle}
      />
    </label>
  );
}

export const meta = () => ({
  title: ["fortnite:interactive-map.title", "Interactive Map"],
  description: [
    "fortnite:interactive-map.desc",
    "Explore the ever-evolving Fortnite island with our interactive map. Discover spawn locations for weapons and items, track down secret chests, and plan your route to victory. Stay updated on the latest changes and find the best spots for your gameplay.",
  ],
});

const MapImpl = InteractiveMap;
export default MapImpl;
